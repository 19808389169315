.firstPage {
    background-image: url('https://lawsuit-winning.com/hairstraightener1/img/bg-masthead.jpg');
    /* background-position: -20px center; */
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    height: auto !important;
    padding: 2rem;
}

@media screen and (max-width: 768px) {
    .firstPage {
        background: url('https://lawsuit-winning.com/hairstraightener1/img/bg-masthead-md.jpg') no-repeat top center '#02457e' ;
        height: auto !important;
    }
}

@media screen and (max-width: 572px) {
    .firstPage {
        background: url('https://lawsuit-winning.com/hairstraightener1/img/bg-masthead-sm.jpg') no-repeat top center '#02457e' ;
        height: auto !important;
    }
}