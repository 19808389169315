.navbar {
    /* width: 500px;
    aspect-ratio: auto 500 / 85;
    height: 85px; */
    padding-left: 8rem;
    padding-bottom: 1rem;
}

@media screen and (max-width:572px) {
    .navbar {
        padding-left: 1rem;
    }
}